require("dotenv").config();
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const contract = require("../contract.json");

const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const contractAddress = process.env.REACT_APP_CONTRACT;
const baseURI = process.env.REACT_APP_BASE_URI;

const web3 = createAlchemyWeb3(alchemyKey);

// NFT Details
const numNFTs = 50;
const costNFTs = "0.1";


// ------------- Connect Wallet ------------------ //

export const connectWallet = async () => {

  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "👆🏽 Write a message in the text-field above.",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};



// ------------- Get Current Wallet ------------------ // 

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Write a message in the text-field above.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};


// ------------- get num minted NFTs ------------------ //

export const getNumNftsMinted = async () => {
  window.contract = await new web3.eth.Contract(contract.abi, contractAddress);
    let numNFTsMinted = await window.contract.methods.totalSupply().call().then((nftsMinted) => {
      return nftsMinted
    })
    return numNFTsMinted;
}


// ------------- Mint NFT ------------------ //

export const mintNFT = async () => {
    window.contract = await new web3.eth.Contract(contract.abi, contractAddress);

    let numNFTsMinted = await window.contract.methods.totalSupply().call().then((nftsMinted) => {
      return nftsMinted
    });

    if (numNFTsMinted >= numNFTs) {
      alert("Sold Out!");
      return;
    }

    let nextId = parseInt(numNFTsMinted)+1;
    const tokenURI = `${baseURI}/${nextId}`;

    const transactionParameters = {
      'from': window.ethereum.selectedAddress, // must match user's active address.
      'to': contractAddress, // Required except during contract publications.
      // 'gas': "700000",
      'value': parseInt(web3.utils.toWei(`${costNFTs}`,"ether")).toString(16),
      'data': window.contract.methods.safeMint(window.ethereum.selectedAddress, tokenURI).encodeABI() // for user to mint to themselves 
    };
  
    try {
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      return {
        success: true,
        status:
          "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
          txHash,
      };
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
};
