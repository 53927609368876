import { useEffect, useState } from 'react';
import bigKnotRight from './assets/images/bigKnotRight.png'
import medKnotLeftBottom from './assets/images/medKnotLeftBottom.png'
import knotLogoMain from './assets/images/knotLogoMain2.png'
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  getNumNftsMinted
} from "./util/interact.js";

require("dotenv").config();

function App() {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [remainingNFTs, setRemainingNFTs] = useState(0);
  const [priceOfNFTs, setPriceOfNFTs] = useState(0.1);
  const [totalSupplyNFTs, setTotalSupplyNFTs] = useState(50);

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setInterval(async () => {
      const numNftsMinted = await getNumNftsMinted();
      setRemainingNFTs(numNftsMinted);
    }, 1000);
    setWallet(address);
    setStatus(status);
    addWalletListener();
    
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  // mint button pressed
  const onMintPressed = async () => {
    const { success, status } = await mintNFT();
    setStatus(status);
    if (success) {
      alert("Please wait while Deez Knots are being processed.")
    } else {
      alert("Transaction Canceled")
    }
  };

  return (
    <div className='container stackableContainer-parent-mobile' style={styles.container}>
      <div className='stackableSubContainer-parent-mobile' style={styles.child}>
      <nav className='nav-mobile' style={styles.nav}>
        <div style={styles.buttonContainer}>
          {/* connect wallet button */}
          <button style={styles.connectButton} id="walletButton" onClick={connectWalletPressed}>
            {walletAddress.length > 0 ? (
              "Connected: " +
              String(walletAddress).substring(0, 6) + "..." + String(walletAddress).substring(38)
            ) : (
              <span>Connect Wallet</span>
            )}
          </button>
        </div>
      </nav>
          <div className='stackableSubContainer-child-mobile' style={styles.subContainer1}>
            <div className='controls-mobile' style={styles.controlsContainer}>
              <div className='stackableSubContainer-parent-mint-mobile' style={styles.mintContainer}>
                <div className='stackableSubContainer-child-mint-mobile'>
                  <img className='logo-main-mobile' style={styles.knotLogoMain} src={knotLogoMain} alt="" />
                </div>
                <div className='stackableSubContainer-child-mint-mobile' style={styles.mintBox}>
                  <div style={styles.whiteTextSm}>Price</div>
                  <div style={styles.whiteTextLg}>{priceOfNFTs} ETH</div>
                  <br></br>
                  <div style={styles.whiteTextSm}>Remaining</div>
                  <div style={styles.whiteTextLg}>{remainingNFTs}/{totalSupplyNFTs}</div>
                  <br></br>
                    <button disabled={walletAddress.length === 0} style={styles.roundedBtnWhite} onClick={onMintPressed}>
                      {parseInt(remainingNFTs) === parseInt(totalSupplyNFTs) ? "Sold Out!" : "Mint"}
                    </button>
                </div>
              </div>
            </div>
          </div>
          <div className='stackableSubContainer-child-mobile bigKnot-mobile' style={styles.subContainer1}>
            <div style={styles.bigKnotContainer}>
              <img style={styles.bigKnot} src={bigKnotRight} alt="" />
            </div>
          </div>
      </div>
      <div className='stackableSubContainer-parent-mobile' style={styles.child}>
          <div className='stackableSubContainer-child-mobile' style={styles.subContainer2}>
              <img style={styles.medKnotLeftBottom} src={medKnotLeftBottom} alt="" />
          </div>
          <div className='stackableSubContainer-child-mobile' style={styles.subContainer2}>
            <div className='infobox-mobile' style={styles.infoBoxContainer}>
                <p style={styles.whiteTextLg}>No sign of existence and no behavior that relates to a living being. But a form inspired by life, a manipulation of an existing asset, but with a new use. Many forms of one who address it as the same but the wrong use could serve to be fatal. A curse when un-wanted, and a blessing when intended, our society relies on the simpilest of forms. The Knot.</p>
            </div>
          </div>

      </div>

    </div>
  );
}
const styles = {
  container: {
    height: "100vh", // for the snap scroll
    overflowY: "auto", // for the snap scroll
    overscrollBehaviorY: "contain", // for the snap scroll
    scrollSnapType: "y mandatory" // for the snap scroll
  },
  child: {
    width: "100vw",
    height: "100vh",
    margin: "0 auto",
    display: "inline-flex",
    justifyContent: "space-between",
    scrollSnapAlign: "center", // for the snap scroll
    flexWrap: "wrap" // when mobile, stack divs
  },
  page: {
    width: "100vw",
    height: "100vh"
  },
  nav: {
    width: "100vw",
    position: "reletive",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "10%",
    position: "absolute",
  },
  
  subContainer1: {
    width: "50vw",
    // minWidth: "300px",
    height: "100vh",
    margin: "0 auto",
    position: "reletive",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  },
  subContainer2: {
    width: "50%",
    position: "reletive",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap"
  },


  

  controlsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginLeft: "15vw"
  },
  mintContainer: {
    // width: "50%",
    display: "inline-flex",
    alignItems: "center",
    float: "right"
  },
  knotLogoMain: {
    width: "20vh",
    marginRight: "3vw"
  },
  infoBoxContainer: {
    width: "60%",
  },


  bigKnotContainer: {
    width: "100%",
  },
  bigKnot: {
    width: "78vh",
    maxWidth: "90%",
    zIndex: "-10000",
    float: "right",
    overflow: "hidden",
  },



  
  medKnotLeftBottom: {
    width: "50vh",
    maxWidth: "100vw"
  },
  whiteTextSm: {
    color: "white",
    fontSize: "1.5vh",
    fontWeight: "300"
  },
  whiteTextLg: {
    color: "white",
    fontSize: "2.5vh",
    fontWeight: "300"
  },

  roundedBtnWhite: {
    padding: "7px",
    border: "none",
    borderRadius: "20px",
    color: "black",
    background: "white",
    width: "100px",
    fontSize: "14px"
  },
  connectButton: {
    unset: "all",
    padding: "9px 16px",
    maxHeight: "40px",
    border: "1px solid #fff",
    color: "white",
    background: "black",
    borderRadius: "20px",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "300",
    textAlign: "center",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "2vw",
    marginLeft: "2vw"
  },
}
export default App;
